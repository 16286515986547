export default defineNuxtRouteMiddleware((to, from) => {
  const timeZone = useCookie('tz')
  const { isLoggedIn, auth, doNotRedirectAfterLogout } = storeToRefs(useAuthStore())
  const { $analytics } = useNuxtApp()
  const { autoUpdateTimezone } = useUserTimezone()

  if (!isLoggedIn.value && !doNotRedirectAfterLogout.value) {
    const { href } = useRequestURL()
    logger.debug('Redirect to login page', {
      href,
      to,
      from,
    })
    /**
     * Black list hrefs to ignore redirect to after login
     * If value is string, it will compare with route name
     * If value is RegExp, it will test with current href
     * @type {Array<string | RegExp>}
     */
    const blackListHrefs: Array<string | RegExp> = ['index', 'logout']
    const isIgnore = blackListHrefs.some((item) => {
      if (typeof item === 'string') {
        return item === to.name
      }

      if (item instanceof RegExp) {
        return item.test(href)
      }

      return false
    })

    const query: Record<string, string> = {}
    if (!isIgnore) {
      query.redirect = href
    }

    return navigateTo({
      name: 'login',
      query,
    })
  }

  // If user is logged in
  if (isLoggedIn.value) {
    // Update timezone
    autoUpdateTimezone()
    timeZone.value = auth.value.timezone

    // Identify analytics
    $analytics.identify(auth.value.id, {
      email: auth.value.email,
      name: auth.value.fullName,
    })
  }
})
